import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-blocker',
  templateUrl: './blocker.component.html',
  styleUrls: ['./blocker.component.scss'],
})
export class BlockerComponent {
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() progress: number = 0;
}
