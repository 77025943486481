import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ProgressStatusEnum } from '../domain/common';
import { ConfirmPasswordData } from '../domain/confirm-password-data';
import { ConfirmPasswordProgress } from '../domain/confirm-password-progress';
import { CreatePasswordData } from '../domain/create-password-data';
import { CreatePasswordProgress } from '../domain/create-password-progress';
import { ForgotPasswordData } from '../domain/forgot-password-data';
import { ForgotPasswordProgress } from '../domain/forgot-password-progress';
import { SignInData } from '../domain/sign-in-data';
import { SignInProgress } from '../domain/sign-in-progress';
import { SignOutProgress } from '../domain/sign-out-progress';
import { AuthenticationService } from '../interfaces/authentication-service';
import { AuthValidationMessageService } from './auth-validation-message.service';
import { CognitoAuthenticationServiceImpl } from './cognito-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthenticationService, CanActivateChild {
  state = new BehaviorSubject<{
    status: ProgressStatusEnum;
    response?: any;
  }>({
    status: ProgressStatusEnum.STAND_BY,
  });

  roleState = new BehaviorSubject<{ roles: string[] }>({ roles: ['PUBLIC'] });

  constructor(
    private authenticationService: CognitoAuthenticationServiceImpl,
    private router: Router,
    private snack: MatSnackBar,
    private authValidationMessage: AuthValidationMessageService,
  ) {}

  get userAttributes() {
    return this.authenticationService.userAttributes;
  }

  get events() {
    return this.authenticationService.events;
  }

  signIn(signInData: SignInData): Observable<SignInProgress> {
    return this.authenticationService.signIn(signInData).pipe(
      catchError(error => {
        this.authValidationMessage.open(error.message);
        return of<SignInProgress>({
          status: ProgressStatusEnum.STAND_BY,
        });
      }),
      tap((result: SignInProgress) => {
        this.state.next(result);
        if (result.status == 'SUCCESS') {
          this.router.navigate(['/dashboard']);
        }
      }),
    );
  }

  signOut(): Observable<SignOutProgress> {
    this.state.next({ status: ProgressStatusEnum.STAND_BY });
    return this.authenticationService.signOut().pipe(
      tap(() => this.router.navigateByUrl('/login?updated=1')),
      tap(() => {
        this.state.next({ status: ProgressStatusEnum.STAND_BY, response: {} });
      }),
    );
  }
  createNewPassword(createPasswordData: CreatePasswordData): Observable<CreatePasswordProgress> {
    return this.authenticationService.createNewPassword(createPasswordData).pipe(
      catchError(error => {
        this.authValidationMessage.open(error.message);
        return of<CreatePasswordProgress>({
          status: ProgressStatusEnum.NEW_PASSWORD_REQUIRED,
        });
      }),
      tap(currentStatus => {
        if (currentStatus.status == 'SUCCESS') {
          this.state.next({ status: ProgressStatusEnum.STAND_BY });
          this.snack.open('Senha trocada com sucesso!', undefined, {
            duration: 3000,
          });
        } else {
          this.state.next({ status: currentStatus.status });
        }
      }),
    );
  }

  isLogged() {
    return this.authenticationService.isLogged();
  }

  isSessionValid() {
    return this.authenticationService.isSessionValid();
  }

  canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const logged = this.isLogged();
    if (!logged) {
      this.router.navigateByUrl('/login?sessionExpired=1');
    }
    return logged;
  }

  getUserToken(): Observable<String> {
    return this.authenticationService.getUserToken();
  }

  confirmPassword(confirmPasswordData: ConfirmPasswordData): Observable<ConfirmPasswordProgress> {
    this.state.next({ status: ProgressStatusEnum.ONGOING });
    return this.authenticationService.confirmPassword(confirmPasswordData).pipe(
      catchError(error => {
        this.authValidationMessage.open(error.message);
        return of<ConfirmPasswordProgress>({
          status: ProgressStatusEnum.PASSWORD_RESET,
        });
      }),
      tap(currentStatus => {
        if (currentStatus.status == 'SUCCESS') {
          this.state.next({ status: ProgressStatusEnum.STAND_BY });
          this.snack.open('Senha trocada com sucesso!', undefined, {
            duration: 3000,
          });
        } else {
          this.state.next({ status: currentStatus.status });
        }
      }),
    );
  }

  requestVerificationCode(): void {
    this.state.next({
      status: ProgressStatusEnum.FORGOT_PASSWORD,
    });
  }

  forgotPassword(forgotPasswordData: ForgotPasswordData): Observable<ForgotPasswordProgress> {
    this.state.next({ status: ProgressStatusEnum.ONGOING });
    return this.authenticationService.forgotPassword(forgotPasswordData).pipe(
      catchError(() => of<ForgotPasswordProgress>({ status: ProgressStatusEnum.STAND_BY })),
      tap(currentStatus => {
        this.state.next({ status: currentStatus.status });
      }),
    );
  }

  get roles() {
    return this.roleState.value.roles;
  }
}
