import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snack: MatSnackBar) {}

  openInfo = (message: string, duration?: number, panelClass = 'snack-info') => {
    return this.open(message, duration, panelClass);
  };

  openSuccess = (message: string, duration = 5000, panelClass = 'snack-success') => {
    return this.open(message, duration, panelClass);
  };

  openWarn = (message: string, duration?: number, panelClass = 'snack-warn') => {
    return this.open(message, duration, panelClass);
  };

  openError = (message: string, duration?: number, panelClass = 'snack-error') => {
    return this.open(message, duration, panelClass);
  };

  open = (message: string, duration?: number, panelClass?: string) => {
    const config = new MatSnackBarConfig();

    config.horizontalPosition = 'right';
    config.verticalPosition = 'top';
    config.duration = duration;

    config.panelClass = panelClass;

    return this.snack.open(message, 'X', config);
  };
}
