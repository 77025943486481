import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { IBreadCrumb } from './breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'label';
  breadcrumbs: IBreadCrumb[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => (this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root)));
  }

  buildBreadCrumb(route: ActivatedRoute, breadcrumbs: IBreadCrumb[] = []): any {
    const children: ActivatedRoute[] = route.children;
    if (children.length == 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const data: string = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (data) {
        const label = this.translateService.instant(data);
        breadcrumbs.push({ label });
      }

      return this.buildBreadCrumb(child, breadcrumbs);
    }
  }
}
