import { Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  name = '';

  isVisible = false;

  newPassword = '';

  sessionId = '';

  verificationCode = '';

  loginData = {
    username: '',
    password: '',
  };

  constructor(private authSvc: AuthService) {}

  submit() {
    this.authSvc.signIn(this.loginData).subscribe();
  }

  requestVerificationCode() {
    this.authSvc.requestVerificationCode();
  }

  forgotPassword() {
    this.authSvc.forgotPassword({ username: this.loginData.username }).subscribe();
  }

  get state() {
    return this.authSvc.state;
  }

  get status() {
    return this.authSvc.state.pipe(map(r => r.status));
  }

  completeNewPasswordChallenge() {
    this.authSvc
      .createNewPassword({
        ...this.loginData,
        newPassword: this.newPassword,
        sessionId: this.sessionId,
        name: this.name,
      })
      .subscribe();
  }

  confirmPassword() {
    this.authSvc
      .confirmPassword({
        verificationCode: this.verificationCode,
        newPassword: this.newPassword,
      })
      .subscribe();
  }
}
