<ngx-charts-bar-vertical-stacked
  [scheme]="colorScheme"
  [results]="result"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [animations]="animations"
  [showDataLabel]="showDataLabel"
  [dataLabelFormatting]="currencyLabelFormatting"
  [yAxisTickFormatting]="currencyLabelFormatting"
  [xAxisTickFormatting]="dateLabelFormatting"
  [legendTitle]="legendTitle"
>
</ngx-charts-bar-vertical-stacked>
