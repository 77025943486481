<mat-divider class="card-horizontal-divider"></mat-divider>
<div class="table-container" *ngIf="hasDataSource()">
  <div class="row mb-2">
    <div class="col-md-12 text-end"></div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>{{ 'common.index' | translate }}</th>
      <td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{ 'common.process.name' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="row table-inner-row">
          <div class="label-item col-md-4">
            <span>{{ 'common.process.name' | translate }}</span>
            <p>{{ 'common.processTitle.' + element.name | translate }}</p>
          </div>
          <div class="label-item col-md-8">
            <span>{{ 'common.process.description' | translate }}</span>
            <p>{{ element.description }}</p>
          </div>
          <div class="label-item col-md-3">
            <span>{{ 'common.process.status' | translate }}</span>
            <div class="d-flex">
              <p
                [class.label-error]="element.status === ProcessStatusEnum.ERROR"
                [class.label-success]="element.status === ProcessStatusEnum.SUCCESS"
                [class.label-info]="element.status === ProcessStatusEnum.PROCESSING"
                [class.label-warn]="element.status === ProcessStatusEnum.CANCELED"
              >
                {{ 'common.processStatus.' + element.status | translate }}
              </p>

              <ng-container *ngIf="element.status === ProcessStatusEnum.ERROR">
                <button
                  class="align-content-center"
                  mat-icon-button
                  (click)="showError(element)"
                  [matTooltip]="'common.process.showDetail' | translate"
                >
                  <mat-icon class="process-error-icon show-detail-icon label-error">error_outline</mat-icon>
                </button>
              </ng-container>
            </div>
          </div>
          <div class="label-item col-md-3">
            <span>{{ 'common.process.createdBy' | translate }}</span>
            <p>{{ element.createdBy }}</p>
          </div>
          <div class="label-item col-md-2">
            <span translate="">{{ 'common.process.createdAt' }}</span>
            <p>{{ element.createdAt | localDateHour }}</p>
          </div>
          <div
            *ngIf="element.status !== ProcessStatusEnum.PROCESSING && element.status !== ProcessStatusEnum.INITIAL"
            class="label-item col-md-2"
          >
            <span>{{ 'common.process.endedAt' | translate }}</span>
            <p *ngIf="element.status !== ProcessStatusEnum.PROCESSING">{{ element.updatedAt | localDateHour }}</p>
          </div>
          <div class="label-item col-md-2" *ngIf="element.status !== ProcessStatusEnum.PROCESSING">
            <span>{{ 'common.process.processTime' | translate }}</span>
            <p>{{ element.processTime }}</p>
          </div>
          <div class="label-item col-md-2" *ngIf="element.status === ProcessStatusEnum.CANCELED">
            <span>{{ 'common.process.cancelatedBy' | translate }}</span>
            <p>{{ element.updatedBy }}</p>
          </div>
          <div class="col-md-12 d-flex align-items-center">
            <ng-container *ngIf="element.status === ProcessStatusEnum.PROCESSING">
              <button
                class="icon-hover"
                mat-icon-button
                (click)="onCancel(element)"
                [matTooltip]="'common.btn.cancelar' | translate"
              >
                <mat-icon>stop</mat-icon>
              </button>
            </ng-container>
            <mat-progress-bar
              class="mat-progress-bar-slow-5s"
              [class.mat-progress-bar-error]="element.status === ProcessStatusEnum.ERROR"
              [class.mat-progress-bar-success]="element.status === ProcessStatusEnum.SUCCESS"
              [class.mat-progress-bar-warn]="element.status === ProcessStatusEnum.CANCELED"
              [mode]="element.progress ? 'determinate' : 'indeterminate'"
              [value]="element.progress"
            ></mat-progress-bar>
            <ng-container *ngIf="element.status === ProcessStatusEnum.PROCESSING">
              <span
                class="p-1"
                [class.label-error]="element.status === ProcessStatusEnum.ERROR"
                [class.label-success]="element.status === ProcessStatusEnum.SUCCESS"
                [class.label-info]="element.status === ProcessStatusEnum.PROCESSING"
                [class.label-warn]="element.status === ProcessStatusEnum.CANCELED"
                >{{ element.progress | number: '1.2-2' }}%</span
              >
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="handlePageEvent($event)"
    aria-label="Select page"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
