<ngx-charts-advanced-pie-chart
  [scheme]="colorScheme"
  [results]="result"
  [gradient]="gradient"
  [valueFormatting]="currencyLabelFormatting"
  [nameFormatting]="labelFormatting"
  [tooltipDisabled]="true"
  [animations]="false"
>
</ngx-charts-advanced-pie-chart>
