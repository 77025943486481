import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { PermissionService } from '../services/permission.service';

/**
 * Shows a specific html item based on the presence of a given permission group
 * Example using the PermissionDirective:
 *    <div *appPermission="['CashAdvance']">
 *        <p> If you can see me, you have CashAdvance role</p>
 *    </div>
 *    <div *appPermissionNot="['CashAdvance']">
 *        <p> If you can see me, you don't have CashAdvance role</p>
 *    </div>
 */
@Directive({
  selector: '[appPermission], [appPermissionNot]',
})
export class PermissionDirective implements OnInit {
  @Input() appPermission: string[];
  @Input() appPermissionNot: string[];

  constructor(
    private permissionService: PermissionService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnInit() {
    this.permissionService.isAllowed(this.appPermission || this.appPermissionNot).subscribe(result => {
      if (this.appPermission && result) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this.appPermissionNot && !result) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
