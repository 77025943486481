import { Directive, Input, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormFunction } from '../utils/functions/form.functions';

@Directive({
  selector: '[appFormErrorSubmit]',
})
export class FormSubmitDirective {
  @Input() validationControl: FormGroup;

  @HostListener('click', ['$event'])
  handleClickEvent() {
    FormFunction.markAsTouched(this.validationControl);
  }
}
