import { Injectable } from '@angular/core';

import { AuthErrorMessageEnum } from '../domain/sign-in-error-message';

@Injectable({
  providedIn: 'root',
})
export class CognitoErrorMessageService {
  constructor() {}

  message = (errorMessage: any): AuthErrorMessageEnum => {
    switch (errorMessage) {
      case 'Password does not conform to policy: Password not long enough': {
        return AuthErrorMessageEnum.NOT_LONG_ENOUGH;
      }
      case 'Password does not conform to policy: Password must have lowercase characters': {
        return AuthErrorMessageEnum.MUST_HAVE_LOWERCASE;
      }
      case 'Password does not conform to policy: Password must have uppercase characters': {
        return AuthErrorMessageEnum.MUST_HAVE_UPPERCASE;
      }
      case 'Password does not conform to policy: Password must have especial characters': {
        return AuthErrorMessageEnum.MUST_HAVE_ESPECIAL_CHARACTER;
      }
      case 'Password does not conform to policy: Password must have numeric characters': {
        return AuthErrorMessageEnum.MUST_HAVE_NUMERIC_CHARACTER;
      }
      case 'Attempt limit exceeded, please try after some time.': {
        return AuthErrorMessageEnum.ATTEMPTS_LIMIT_EXCEEDED;
      }
      case 'Password reset required for the user': {
        return AuthErrorMessageEnum.PASSWORD_RESET;
      }
      case 'Incorrect username or password.': {
        return AuthErrorMessageEnum.INCORRECT_PASSWORD_USERNAME;
      }
      case 'Password attempts exceeded': {
        return AuthErrorMessageEnum.ATTEMPTS_LIMIT_EXCEEDED;
      }
      case 'Invalid verification code provided, please try again.': {
        return AuthErrorMessageEnum.INVALID_TOKEN;
      }
      default: {
        return AuthErrorMessageEnum.UNDEFINED;
      }
    }
  };
}
