import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  CustomPaginatorIntl,
  dateFormat,
  getCurrentLang,
  translationAppInitializerFactory,
  translationHttpLoaderFactory,
} from './translate-loader';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'pt-BR',
      loader: {
        provide: TranslateLoader,
        useFactory: translationHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: translationAppInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    { provide: LOCALE_ID, useFactory: getCurrentLang, deps: [TranslateService] },
    { provide: MAT_DATE_LOCALE, useFactory: getCurrentLang, deps: [TranslateService] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } },
    { provide: MAT_DATE_FORMATS, useFactory: dateFormat, deps: [TranslateService] },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl, deps: [TranslateService] },
  ],
})
export class TranslateLoaderModule {}
