import { Injectable } from '@angular/core';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import { ReplaySubject } from 'rxjs';

import { UserRoles } from '../../../../constants/user-roles.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private cognitoUserPool: CognitoUserPool) {}

  isAllowed(roles?: string[]) {
    const response = new ReplaySubject<boolean>();
    this.cognitoUserPool?.getCurrentUser()?.getSession((err: Error, session: CognitoUserSession | null) => {
      if (err != null) {
        response.error(false);
      } else {
        const sessionDecoded: any = jwt_decode(session?.getIdToken().getJwtToken()!!);
        const userRoles = sessionDecoded['cognito:groups'];

        if (userRoles && userRoles.some((x: string) => x === UserRoles.ADMIN)) {
          response.next(true);
        } else if (userRoles && userRoles.some((x: string) => x === roles?.find((y: string) => y === x))) {
          response.next(true);
        } else {
          response.next(false);
        }
      }
      response.complete();
    });

    return response;
  }
}
