import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFormError]',
})
export class FormErrorDirective implements OnInit, OnDestroy {
  constructor(private elementRef: ElementRef, private control: NgControl, private translateService: TranslateService) {}

  @Input() appFormErrorCustomMessage: { [key: string]: string };

  private errorSpanId: string = '';
  private statusChangeSubscription: Subscription = new Subscription();
  private errorMessages = {
    required: 'common.message.requiredField',
    minlength: 'common.message.requiredMinLength',
    maxlength: 'common.message.requiredMaxLength',
    invalidCNPJ: 'common.message.invalidCNPJ',
    email: 'common.message.invalidEmail',
    mask: 'common.message.invalidPhone',
  };

  ngOnDestroy(): void {
    this.statusChangeSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.errorSpanId = this.control.name ? this.control.name.toString() + new Date() + '-error-message' : '';
    if (this.control.statusChanges)
      this.statusChangeSubscription = this.control.statusChanges.subscribe(status => {
        if (status == 'INVALID') {
          this.showError();
        } else {
          this.removeError();
        }
      });
  }

  @HostListener('blur', ['$event'])
  handleBlueEvent() {
    if (this.control.value == null || this.control.value == '') {
      if (this.control.errors) {
        this.showError();
      } else {
        this.removeError();
      }
    }
  }

  private showError() {
    this.removeError();
    const valErrors = this.control.errors;
    if (valErrors) {
      const firstKey = Object.keys(valErrors)[0];
      const errorMessageKey = firstKey;
      const messages = Object.assign({}, this.errorMessages, this.appFormErrorCustomMessage);
      const errorMessage = this.translateService.instant(messages[errorMessageKey], valErrors);
      const errSpan = '<span style="color:red;" id="' + this.errorSpanId + '">' + errorMessage + '</span>';
      this.elementRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
    }
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) errorElement.remove();
  }
}
