import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CartListItemComponent } from './components/cart-list-item/cart-list-item.component';
import { CartListComponent } from './components/cart-list/cart-list.component';
import { DialogProcessErrorComponent } from './components/dialog-process-error/dialog-process-error.component';
import { LogoComponent } from './components/logo/logo.component';
import { ProcessListComponent } from './components/process-list/process-list.component';
import { SecuritizationDailyIssueCbbChartComponent } from './components/securitization/securitization-daily-issue-cbb-chart/securitization-daily-issue-cbb-chart.component';
import { SecuritizationDailyLiquidationChartComponent } from './components/securitization/securitization-daily-liquidation-chart/securitization-daily-liquidation-chart/securitization-daily-liquidation-chart.component';
import { SecuritizationTotalIssueCbbChartComponent } from './components/securitization/securitization-total-issue-cbb-chart/securitization-total-issue-cbb-chart.component';
import { SecuritizationTotalLiquidationChartComponent } from './components/securitization/securitization-total-liquidation-chart/securitization-total-liquidation-chart.component';
import { StatusTrackerComponent } from './components/status-tracker/status-tracker.component';
import { AclDirective } from './directives/acl.directive';
import { FormSubmitDirective } from './directives/form-error-submit.directive';
import { FormErrorDirective } from './directives/form-error.directive';
import { OnlyDevDirective } from './directives/only-dev.directive';
import { PermissionDirective } from './permission/directives/permission.directive';
import { PermissionModule } from './permission/permission.module';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { LocalDateHourSecPipe } from './pipes/local-date-hour-sec.pipe';
import { LocalDateHourPipe } from './pipes/local-date-hour.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';

@NgModule({
  declarations: [
    LogoComponent,
    CartListComponent,
    CartListItemComponent,
    AclDirective,
    LocalDatePipe,
    LogoComponent,
    FormErrorDirective,
    FormSubmitDirective,
    BreadcrumbComponent,
    StatusTrackerComponent,
    OnlyDevDirective,
    StatusTrackerComponent,
    LocalDateHourPipe,
    ProcessListComponent,
    DialogProcessErrorComponent,
    LocalDateHourSecPipe,
    SecuritizationDailyLiquidationChartComponent,
    CnpjPipe,
    SecuritizationTotalLiquidationChartComponent,
    SecuritizationDailyIssueCbbChartComponent,
    SecuritizationTotalIssueCbbChartComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    PermissionModule,
    MatStepperModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTableModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    NgxChartsModule,
  ],
  exports: [
    LogoComponent,
    CartListComponent,
    CartListItemComponent,
    AclDirective,
    LocalDatePipe,
    LocalDateHourPipe,
    LocalDateHourSecPipe,
    LogoComponent,
    FormErrorDirective,
    FormSubmitDirective,
    BreadcrumbComponent,
    OnlyDevDirective,
    StatusTrackerComponent,
    PermissionDirective,
    ProcessListComponent,
    SecuritizationDailyLiquidationChartComponent,
    SecuritizationTotalLiquidationChartComponent,
    SecuritizationDailyIssueCbbChartComponent,
    SecuritizationTotalIssueCbbChartComponent,
    CnpjPipe,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class SharedModule {}
