import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localDateHour',
})
export class LocalDateHourPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string | number | Date, format?: string, timezone = 'UTC -6'): unknown {
    return new DatePipe(this.translate.currentLang).transform(
      value,
      format || this.translate.instant('common.mask.dateHour.short'),
      timezone,
    );
  }
}
