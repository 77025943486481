/**
 * Property decorator
 * counting to prevent race conditions
 * get `false` only after the same amount of
 * changes to true are changed to false
 *
 * example:
 * @StackBoolean() loading = false;
 *
 * this.loading = true; // count = 1 value is `true`
 * this.loading = true; // count = 2 value is `true`
 * this.loading = false; // count = 1 value is `true`
 * this.loading = false; // count = 0 value is `false`
 */
export function StackBoolean<T>() {
  return (target: T, property: string | symbol) => {
    const key = Symbol('stackBoolean');
    Object.defineProperty(target, property, {
      get(): boolean {
        return this[key] > 0;
      },
      set(value: boolean) {
        // initialize value
        if (this[key] === undefined) {
          this[key] = 0;
        }
        // update value
        if (value) {
          this[key] += 1;
        } else if (this[key] > 0) {
          this[key] -= 1;
        }
      },
    });
  };
}
