import { FormGroup } from '@angular/forms';

export class FormFunction {
  /**
   * Clean errors from all controls of the formGroup setting them as null
   * @method cleanFormErrors
   * @param {FormGroup} formGroup the formGroup which will have its errors cleaned
   */
  static cleanFormErrors = (formGroup: FormGroup) => {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];
      control.setErrors(null);
    });
  };

  static markAsTouched(formGroup: FormGroup): void {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }
}
