import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Directive({
  selector: '[appOnlyDev]',
})
export class OnlyDevDirective implements OnInit {
  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  ngOnInit() {
    if (!environment.production) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
