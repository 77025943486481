export enum ProgressStatusEnum {
  ONGOING = 'ONGOING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  STAND_BY = 'STAND_BY',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  PASSWORD_RESET = 'PASSWORD_RESET',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}
