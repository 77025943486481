export default class Utils {
  static removeNullProperties(obj: { [key: string]: string }): { [key: string]: string } {
    const keys: string[] = Object.keys(obj);
    const initialValue: { [key: string]: string } = {};
    return keys
      .filter(value => obj[value] != null && obj[value] !== '')
      .reduce((previousValue, currentValue) => {
        previousValue[currentValue] = obj[currentValue];
        return previousValue;
      }, initialValue);
  }
}
