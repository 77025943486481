export enum AuthErrorMessageEnum {
  INCORRECT_PASSWORD_USERNAME = 'INCORRECT_PASSWORD_USERNAME',
  ATTEMPTS_LIMIT_EXCEEDED = 'ATTEMPTS_LIMIT_EXCEEDED',
  PASSWORD_RESET = 'PASSWORD_RESET',
  UNDEFINED = 'UNDEFINED',
  NOT_LONG_ENOUGH = 'NOT_LONG_ENOUGH',
  MUST_HAVE_LOWERCASE = 'MUST_HAVE_LOWERCASE',
  MUST_HAVE_UPPERCASE = 'MUST_HAVE_UPPERCASE',
  MUST_HAVE_NUMERIC_CHARACTER = 'MUST_HAVE_NUMERIC_CHARACTER',
  MUST_HAVE_ESPECIAL_CHARACTER = 'MUST_HAVE_ESPECIAL_CHARACTER',
  INVALID_TOKEN = 'INVALID_TOKEN',
}
