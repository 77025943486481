import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localDate',
})
export class LocalDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string | number | Date | undefined, format?: string): unknown {
    return new DatePipe(this.translate.currentLang).transform(
      value,
      format || this.translate.instant('common.mask.date.short'),
    );
  }
}
