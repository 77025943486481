import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

import { SecuritizationDebtRecordIssueItemService } from '../../../../individual/securitization/services/securitization-debt-record-issue-item.service';
import { LoadingModule } from '../../../loading/loading.module';
import { LoadingService } from '../../../loading/services/loading.service';

@Component({
  selector: 'app-securitization-total-issue-cbb-chart',
  templateUrl: './securitization-total-issue-cbb-chart.component.html',
  styleUrls: ['./securitization-total-issue-cbb-chart.component.scss'],
  providers: [LoadingModule.providers()],
})
export class SecuritizationTotalIssueCbbChartComponent implements OnInit, OnChanges {
  @Input() dateFrom: moment.Moment = moment();
  @Input() dateTo: moment.Moment = moment();

  result: any[] = [];

  // options
  gradient: boolean = false;
  currencyLabelFormatting = (value: number) => {
    return new CurrencyPipe(this.translate.currentLang).transform(value || '', 'BRL');
  };
  labelFormatting = (value: string) => {
    return this.translate.instant(`securitization.cbb.type.${value}`);
  };
  colorScheme: any = {
    domain: ['#4d93d8', '#66d84d'],
  };

  constructor(
    private securitizationDebtRecordIssueItemService: SecuritizationDebtRecordIssueItemService,
    private loadingService: LoadingService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.dateFrom?.firstChange && !changes?.dateTo?.firstChange) {
      this.loadData();
    }
  }

  private loadData = () => {
    if (!this.dateFrom || !this.dateTo) return;

    this.loadingService.block();
    this.securitizationDebtRecordIssueItemService
      .getIssueCCBAmountByDate(this.dateFrom.format('yyyy-MM-DD'), this.dateTo.format('yyyy-MM-DD'))
      .pipe(finalize(() => this.loadingService.release()))
      .subscribe(response => {
        this.result = [];
        response.forEach(item => {
          let ccbTypeAmount = this.result.find(m => m.name === item.ccbType);
          const hasInserted = Boolean(ccbTypeAmount);

          ccbTypeAmount = ccbTypeAmount || { value: 0 };
          ccbTypeAmount.name = item.ccbType;
          ccbTypeAmount.value += item.amount;

          if (!hasInserted) {
            this.result.push(ccbTypeAmount);
          }
        });
      });
  };
}
