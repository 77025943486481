import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';

import { IPage } from '../../../model/page.model';

export interface BasicFilter {
  page: number;
  size: number;
  queryString: string;
  [key: string]: any;
}

export interface State<T> {
  status: 'INITIAL' | 'LOADING' | 'LOADED' | 'EMPTY';
  page: IPage<T> | undefined;
}

export abstract class SpringPaginator<T> {
  totalElements: number = 0;

  private listState = new BehaviorSubject<State<T>>({
    status: 'INITIAL',
    page: {
      content: [],
      size: 0,
      page: 0,
      totalElements: 0,
    },
  });

  private filterState = new BehaviorSubject<BasicFilter>({
    page: 0,
    size: 10,
    queryString: '',
    ccbType: null,
    isDefault: true,
  });

  constructor(public httpClient: HttpClient) {
    this.filterState
      .pipe(
        switchMap(filter => {
          if (!filter.isDefault) {
            return this.fetchAll(filter).pipe(
              tap(r => {
                if (r.content.length > 0) {
                  this.listState.next({
                    status: 'LOADED',
                    page: r,
                  });
                } else {
                  this.listState.next({
                    status: 'EMPTY',
                    page: undefined,
                  });
                }
                this.totalElements = r.totalElements;
              }),
            );
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  get listItems(): Observable<IPage<T> | undefined> {
    return this.listState.pipe(map(state => state.page));
  }

  updateFilter(filter: BasicFilter) {
    this.filterState.next(filter);
  }

  firstPage(filter: BasicFilter) {
    filter.page = 0;
    this.filterState.next(filter);
  }

  nextPage() {
    this.filterState.next({
      ...this.filterState.value,
      page: this.filterState.value.page + 1,
    });
  }

  prevPage() {
    this.filterState.next({
      ...this.filterState.value,
      page: this.filterState.value.page - 1,
    });
  }

  get currentPage(): Observable<number> {
    return this.filterState.pipe(
      map(filter => {
        return filter.page;
      }),
    );
  }

  get filter() {
    return this.filterState;
  }

  abstract fetch(filter: BasicFilter): Observable<IPage<T>>;

  fetchAll(filter: BasicFilter): Observable<IPage<T>> {
    if (this.useMock()) {
      return this.fetchMock();
    } else {
      return this.fetch(filter);
    }
  }

  fetchMock() {
    this.listState.next({ status: 'LOADING', page: undefined });
    const pageable = new MockPaginator(this.mockItems());
    return of(pageable.getPage(this.filterState.value)).pipe(delay(1000));
  }

  get status() {
    return this.listState.pipe(map(state => state.status));
  }

  mockItems(): T[] {
    return [];
  }

  useMock(): boolean {
    return false;
  }

  cleanList() {
    this.listState.next({
      status: 'EMPTY',
      page: undefined,
    });
  }
}

class MockPaginator<T> {
  constructor(private mockItems: T[]) {}

  getPage({ page, size }: BasicFilter): IPage<T> {
    return {
      page,
      size,
      content: this.cut(page, size),
      totalElements: 0,
    };
  }

  countPages(size: number) {
    return Math.ceil(this.mockItems.length / size);
  }

  countItems() {
    return this.mockItems.length;
  }

  cut(page: number, size: number): T[] {
    const offset = page * size;
    return this.mockItems.slice(offset, offset + size);
  }
}

export function mockGenerator<T>(itemCount: number, creator: (index: number, random?: number) => T): T[] {
  const a: T[] = [];
  for (var i = 0; i < itemCount; i++) {
    a.push(creator(i, Math.ceil(Math.random() * 10000)));
  }
  return a;
}
