import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { AppPage } from '../../../shared/components/pagination/pagination-handler';
import { IDebtRecordIssueItem, IssueCCBAmountByDate } from '../domain/debt-record-issue-item.model';

@Injectable({
  providedIn: 'root',
})
export class SecuritizationDebtRecordIssueItemService {
  private url = `${environment.securitizationApiUrl}/v1/debt-record-issue-item`;

  constructor(private httpClient: HttpClient) {}

  getById(id: String): Observable<IDebtRecordIssueItem> {
    return this.httpClient.get<IDebtRecordIssueItem>(`${this.url}/${id}`);
  }

  deleteById(id: String): Observable<IDebtRecordIssueItem> {
    return this.httpClient.delete<IDebtRecordIssueItem>(`${this.url}/${id}`);
  }

  getList(httpParams?: HttpParams): Observable<AppPage<IDebtRecordIssueItem>> {
    return this.httpClient.get<AppPage<IDebtRecordIssueItem>>(this.url, {
      params: httpParams,
    });
  }

  sendCcbToQiTech(id: String, disbursementDate: String): Observable<IDebtRecordIssueItem> {
    return this.httpClient.post<IDebtRecordIssueItem>(`${this.url}/${id}/send-ccb`, {
      disbursementDate: disbursementDate,
    });
  }

  getIssueCCBAmountByDate(dateFrom: string, dateTo: string): Observable<IssueCCBAmountByDate[]> {
    return this.httpClient.get<IssueCCBAmountByDate[]>(`${this.url}/amount-by-date?from=${dateFrom}&to=${dateTo}`);
  }

  cancelCcb(id: String): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${id}/cancel`, {});
  }
}
