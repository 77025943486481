import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { Paging } from '../../../../model/page.model';
import { IProcess, IProcessItem, ProcessItemStatusEnum } from '../../../../model/process.model';
import { SecuritizationProcessService } from '../../../individual/securitization/services/securitization-process.service';
import { LoadingModule } from '../../loading/loading.module';
import { LoadingService } from '../../loading/services/loading.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-dialog-process-error',
  templateUrl: './dialog-process-error.component.html',
  styleUrls: ['./dialog-process-error.component.scss'],
  providers: [LoadingModule.providers()],
})
export class DialogProcessErrorComponent implements OnInit, OnDestroy {
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;
  public pageSizeOptions: number[] = [5, 10, 25, 100];

  public process: IProcess;
  public processItems: IProcessItem[] = [];
  public displayedColumns: string[] = ['status', 'processResponse', 'createdAt'];

  private destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogProcessErrorComponent>,
    private securitizationProcessService: SecuritizationProcessService,
    private toastService: ToastService,
    private translate: TranslateService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.process = this.data.process;
    }
    this.getProcessItems();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getProcessItems(paging: Paging = { page: 0, size: 10 }) {
    this.loadingService.block();
    this.securitizationProcessService
      .getProcessItemList(this.process?.id, paging, ProcessItemStatusEnum.ERROR)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.toastService.openError(this.translate.instant('common.message.internalServerError'));
          throw err;
        }),
        finalize(() => this.loadingService.release()),
      )
      .subscribe(res => {
        this.processItems = res.content;
        this.length = res.totalElements;
      });
  }

  isProcessItemsEmpty() {
    return this.processItems ? !(this.processItems.length > 0) : true;
  }

  isProcessResponseEmpty() {
    if (this.process) {
      return this.process.processResponse ? false : true;
    } else {
      return true;
    }
  }

  handlePageEvent(event: PageEvent) {
    this.getProcessItems({ page: event.pageIndex, size: event.pageSize });
  }
}
