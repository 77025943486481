<div class="container">
  <mat-card class="d-flex-align-center w-100">
    <div class="row bad-access w-100">
      <div class="row">
        <mat-icon class="bad-access-icon">mood_bad</mat-icon>
      </div>
      <div class="row">
        <p class="bad-access-text">{{ 'common.message.accessNotAllowed' | translate }}</p>
      </div>
    </div>
  </mat-card>
</div>
