import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { IPage } from '../../../../model/page.model';
import { IUploadProgress, UploadProgressStatusEnum } from '../../../../model/upload-progress.model';
import { BasicFilter, SpringPaginator } from '../../../shared/services/spring-paginator';
import Utils from '../../../shared/utils/functions/array.functions';
import { ILiquidation, ILiquidationAmountByDate } from '../domain/securitization-liquidation';

@Injectable({
  providedIn: 'root',
})
export class SecuritizationLiquidationService extends SpringPaginator<ILiquidation> {
  public uploadProgress: IUploadProgress = {
    progress: 0,
    status: UploadProgressStatusEnum.PENDING,
  };

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  fetch(filter: BasicFilter): Observable<IPage<ILiquidation>> {
    return this.getLiquidationItem(filter);
  }

  getLiquidationItem(filter: BasicFilter): Observable<IPage<ILiquidation>> {
    return this.httpClient
      .get<IPage<ILiquidation>>(`${environment.securitizationApiUrl}/v1/liquidation`, {
        params: { ...Utils.removeNullProperties(filter), sort: 'createdAt,desc' },
      })
      .pipe(
        tap(result => {
          if (result.totalElements == 0) {
            return result;
          }
        }),
      );
  }

  getLiquidationPreview(id: String): Observable<ArrayBuffer> {
    const httpOptions = {
      responseType: 'arraybuffer' as 'json',
    };
    return this.httpClient.get<ArrayBuffer>(
      `${environment.securitizationApiUrl}/v1/liquidation/${id}/preview`,
      httpOptions,
    );
  }

  sendLiquidationToCaptalys(id: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.securitizationApiUrl}/v1/liquidation/${id}/send-captalys`, {});
  }

  getLiquidationAmountByDate(dateFrom: string, dateTo: string): Observable<ILiquidationAmountByDate[]> {
    return this.httpClient.get<ILiquidationAmountByDate[]>(
      `${environment.securitizationApiUrl}/v1/liquidation/amount-by-date?liquidationDateFrom=${dateFrom}&liquidationDateTo=${dateTo}`,
    );
  }
}
