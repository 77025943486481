import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToastService } from '../../shared/services/toast.service';
import { AuthErrorMessageEnum } from '../domain/sign-in-error-message';

@Injectable({
  providedIn: 'root',
})
export class AuthValidationMessageService {
  private translateService: TranslateService;

  constructor(private toastService: ToastService, private injector: Injector) {}

  open = (errorMessage: AuthErrorMessageEnum) => {
    this.translateService = this.injector.get(TranslateService);
    switch (errorMessage) {
      case AuthErrorMessageEnum.PASSWORD_RESET: {
        this.toastService.openError(this.translateService.instant('auth.error.passswordReset'));
        break;
      }
      case AuthErrorMessageEnum.INCORRECT_PASSWORD_USERNAME: {
        this.toastService.openError(this.translateService.instant('auth.error.invalidPasswordUsername'));
        break;
      }
      case AuthErrorMessageEnum.ATTEMPTS_LIMIT_EXCEEDED: {
        this.toastService.openError(this.translateService.instant('auth.error.attemptsLimit'));
        break;
      }
      case AuthErrorMessageEnum.NOT_LONG_ENOUGH: {
        this.toastService.openError(this.translateService.instant('auth.error.passwordNotLongEnough'));
        break;
      }
      case AuthErrorMessageEnum.MUST_HAVE_LOWERCASE: {
        this.toastService.openError(this.translateService.instant('auth.error.passwordLowercase'));
        break;
      }
      case AuthErrorMessageEnum.MUST_HAVE_UPPERCASE: {
        this.toastService.openError(this.translateService.instant('auth.error.passwordUppercase'));
        break;
      }
      case AuthErrorMessageEnum.MUST_HAVE_ESPECIAL_CHARACTER: {
        this.toastService.openError(this.translateService.instant('auth.error.passwordEspecialCharacter'));
        break;
      }
      case AuthErrorMessageEnum.MUST_HAVE_NUMERIC_CHARACTER: {
        this.toastService.openError(this.translateService.instant('auth.error.passwordNumericCharacter'));
        break;
      }
      case AuthErrorMessageEnum.INVALID_TOKEN: {
        this.toastService.openError(this.translateService.instant('auth.error.invalidVerificationToken'));
        break;
      }
      case AuthErrorMessageEnum.UNDEFINED: {
        this.toastService.openError(this.translateService.instant('auth.error.undefined'));
        break;
      }
      default: {
        this.toastService.openError(this.translateService.instant('auth.error.unknown'));
      }
    }
  };
}
