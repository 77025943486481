import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PermissionDirective } from './directives/permission.directive';

@NgModule({
  declarations: [UnauthorizedComponent, PermissionDirective],
  imports: [CommonModule, MatIconModule, MatCardModule, TranslateModule.forChild()],
  exports: [PermissionDirective],
})
export class PermissionModule {}
