import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('assets/i18n') >= 0) {
      return next.handle(req);
    }
    return this.handle(req, next);
  }
  // @TODO fix AuthInterceptor and AuthService (TranslateService) Circular Dependency Injection
  handle(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.getUserToken().pipe(
      switchMap(accessToken => {
        return next.handle(
          req.clone({
            headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
          }),
        );
      }),
    );
  }
}
