<mat-stepper orientation="vertical" #stepper>
  <ng-template matStepperIcon="edit">
    <mat-icon>adjust</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="future">
    <mat-icon>radio_button_unchecked</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="interruption">
    <mat-icon>close</mat-icon>
  </ng-template>

  <mat-step
    *ngFor="let step of steps"
    [label]="step.label"
    [state]="step.state"
    [completed]="step.state === 'done'"
  ></mat-step>
</mat-stepper>
<div *ngIf="hasInterruption">
  <mat-icon class="repproval-info mat-step-icon">error_outline</mat-icon>
  {{ 'cashAdvance.customerStatus.detail.' + this.statusDetail | translate }}
</div>
