import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

import { ICustomerStatusDetailEnum } from '../../../cashadvance-pj/domain/customer';

export class StatusTrackerStep {
  label: string;
  key: string;
  state: 'done' | 'future' | 'edit' | 'interruption' = 'future';
}

@Component({
  selector: 'app-status-tracker',
  templateUrl: './status-tracker.component.html',
  styleUrls: ['./status-tracker.component.scss'],
})
export class StatusTrackerComponent implements OnInit, OnChanges {
  @ViewChild('stepper', { static: true })
  stepper: MatStepper;

  constructor() {}

  @Input()
  steps: StatusTrackerStep[] = [];

  @Input()
  currentStep: StatusTrackerStep;

  @Input()
  hasInterruption: boolean;

  @Input()
  statusDetail: ICustomerStatusDetailEnum;

  ngOnInit(): void {
    this.setCurrentStep();
  }

  ngOnChanges(): void {
    this.setCurrentStep();
  }

  private setCurrentStep() {
    if (this.currentStep) {
      for (let index = 0; index < this.steps.length; index++) {
        const step = this.steps[index];
        step.state = 'done';

        if (this.currentStep.key === step.key) {
          if (this.steps[index]) this.steps[index].state = this.hasInterruption ? 'interruption' : 'done';
          if (this.steps.length > index + 1) {
            this.steps[index + 1].state = 'edit';
            this.stepper.selectedIndex = index + 1;
            break;
          }
        }
      }
    }
  }
}
