import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { IPage, Paging } from '../../../../model/page.model';
import { IProcess, IProcessItem, ProcessItemStatusEnum } from '../../../../model/process.model';

@Injectable({
  providedIn: 'root',
})
export class SecuritizationProcessService {
  private apiUrl: string = `${environment.securitizationApiUrl}/v1/process`;
  private processItemApiUrl: string = `${environment.securitizationApiUrl}/v1/process-item`;

  constructor(private httpClient: HttpClient) {}

  getProcessList(paging: Paging = { page: 0, size: 10 }): Observable<IPage<IProcess>> {
    return this.httpClient.get<IPage<IProcess>>(`${this.apiUrl}?page=${paging.page}&size=${paging.size}`, {
      params: {
        sort: 'createdAt,desc',
      },
    });
  }

  getProcess(processId: string): Observable<IProcess> {
    return this.httpClient.get<IProcess>(`${this.apiUrl}/${processId}`);
  }

  cancelProcess(processId: string) {
    return this.httpClient.put(`${this.apiUrl}/${processId}/cancel`, null);
  }

  getProcessItemList(
    processId: string,
    paging: Paging = { page: 0, size: 10 },
    status?: ProcessItemStatusEnum,
  ): Observable<IPage<IProcessItem>> {
    var params = new HttpParams();
    params = params
      .set('processId', processId)
      .set('page', paging.page)
      .set('size', paging.size)
      .set('sort', 'createdAt,desc');

    if (status) {
      params = params.set('status', status);
    }

    return this.httpClient.get<IPage<IProcessItem>>(`${this.processItemApiUrl}`, { params: params });
  }
}
