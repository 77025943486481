import { Pipe, PipeTransform } from '@angular/core';
import { formatToCNPJ } from 'brazilian-values';

@Pipe({
  name: 'cnpj',
})
export class CnpjPipe implements PipeTransform {
  transform(value?: string): unknown {
    return value ? formatToCNPJ(value) : value;
  }
}
