import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { ProgressStatusEnum } from '../domain/common';
import { ConfirmPasswordData } from '../domain/confirm-password-data';
import { ConfirmPasswordProgress } from '../domain/confirm-password-progress';
import { CreatePasswordData } from '../domain/create-password-data';
import { CreatePasswordProgress } from '../domain/create-password-progress';
import { ForgotPasswordData } from '../domain/forgot-password-data';
import { ForgotPasswordProgress } from '../domain/forgot-password-progress';
import { SignInData } from '../domain/sign-in-data';
import { SignInProgress } from '../domain/sign-in-progress';
import { SignOutProgress } from '../domain/sign-out-progress';
import { AuthenticationService } from '../interfaces/authentication-service';
import { CognitoErrorMessageService } from './cognito-error-message.service';

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthenticationServiceImpl implements AuthenticationService {
  cognitoUser: CognitoUser;

  events = new BehaviorSubject<ProgressStatusEnum>(ProgressStatusEnum.LOADING);

  userAttributes = new BehaviorSubject({
    email: '',
    email_verified: false,
    name: 'Sem nome',
    phone_number: '',
    phone_number_verified: '',
    Username: 'Username',
  });

  constructor(
    private cognitoUserPool: CognitoUserPool,
    private cognitoErrorMessageService: CognitoErrorMessageService,
  ) {
    this.initialize();
  }

  getUserToken = (): Observable<String> => {
    const response = new ReplaySubject<String>();
    this.cognitoUser?.getSession((err: Error, session: CognitoUserSession | null) => {
      if (err != null) {
        response.error('Cannot get cognito user');
      } else {
        response.next(session?.getIdToken().getJwtToken()!!);
      }
      response.complete();
    });

    return response;
  };

  initialize = () => {
    this.cognitoUser = this.cognitoUserPool.getCurrentUser() as CognitoUser;

    this.events.next(ProgressStatusEnum.LOADING);

    this.cognitoUser?.getSession((err: any) => {
      if (err == null) {
        this.getUserAttributes();
      }
    });
  };

  createNewPassword(createPasswordData: CreatePasswordData): Observable<CreatePasswordProgress> {
    return new Observable(subscriber => {
      subscriber.next({
        status: ProgressStatusEnum.ONGOING,
      });
      this.cognitoUser?.getSession((error: any, session: any) => {
        this.cognitoUser?.completeNewPasswordChallenge(
          createPasswordData.newPassword,
          { name: createPasswordData.name },
          {
            onSuccess: () => {
              subscriber.next({
                status: ProgressStatusEnum.SUCCESS,
              });
            },
            onFailure: (error: any) => {
              subscriber.error({
                message: this.cognitoErrorMessageService.message(error.message),
              });
            },
          },
          {
            session: session,
          },
        );
      });
    });
  }

  signIn(signInData: SignInData): Observable<SignInProgress> {
    const userData = {
      Username: signInData.username,
      Pool: this.cognitoUserPool,
    };
    this.cognitoUser = new CognitoUser(userData);
    let authenticationDetails = new AuthenticationDetails({
      Username: signInData.username,
      Password: signInData.password,
    });

    return new Observable(subscriber => {
      subscriber.next({
        status: ProgressStatusEnum.ONGOING,
      });
      this.cognitoUser?.authenticateUser(authenticationDetails, {
        onSuccess: (response: any) => {
          this.userAttributes.next(response);
          this.getUserAttributes();
          subscriber.next({
            status: ProgressStatusEnum.SUCCESS,
          });
        },
        newPasswordRequired: (response: any) => {
          this.userAttributes.next(response);
          subscriber.next({
            status: ProgressStatusEnum.NEW_PASSWORD_REQUIRED,
            response: response,
          });
        },

        onFailure: (error: Error) => {
          if (error.message == 'Password reset required for the user') {
            subscriber.next({
              status: ProgressStatusEnum.PASSWORD_RESET,
            });
          } else {
            subscriber.error({
              message: this.cognitoErrorMessageService.message(error.message),
            });
          }
        },
      });
    });
  }

  getUserAttributes() {
    this.cognitoUser?.getUserData((error: any, data: any) => {
      const attrs = data.UserAttributes.reduce((previous: any, current: any) => {
        return {
          ...previous,
          [current.Name]: current.Value,
          Username: data.Username,
        };
      }, {});
      this.userAttributes.next(attrs);
      this.events.next(ProgressStatusEnum.LOADED);
    });
  }

  signOut(): Observable<SignOutProgress> {
    return new Observable(subscriber => {
      this.cognitoUser?.signOut(() => {
        subscriber.next({
          status: ProgressStatusEnum.STAND_BY,
        });
      });
    });
  }

  isLogged() {
    return this.cognitoUserPool?.getCurrentUser() !== null;
  }

  isSessionValid() {
    return this.cognitoUser?.getSignInUserSession()?.isValid();
  }

  confirmPassword(confirmPasswordData: ConfirmPasswordData): Observable<ConfirmPasswordProgress> {
    return new Observable(subscriber => {
      this.cognitoUser?.confirmPassword(confirmPasswordData.verificationCode, confirmPasswordData.newPassword, {
        onSuccess() {
          subscriber.next({
            status: ProgressStatusEnum.SUCCESS,
          });
        },
        onFailure: (error: any) => {
          subscriber.error({
            message: this.cognitoErrorMessageService.message(error.message),
          });
        },
      });
    });
  }

  forgotPassword(forgotPasswordData: ForgotPasswordData): Observable<ForgotPasswordProgress> {
    this.cognitoUser = new CognitoUser({
      Username: forgotPasswordData.username,
      Pool: this.cognitoUserPool,
    });
    return new Observable(subscriber => {
      this.cognitoUser?.forgotPassword({
        inputVerificationCode() {
          subscriber.next({
            status: ProgressStatusEnum.PASSWORD_RESET,
          });
        },
        onSuccess() {
          subscriber.next({
            status: ProgressStatusEnum.SUCCESS,
          });
        },
        onFailure(error: Error) {
          subscriber.error(error);
        },
      });
    });
  }
}
