import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

import { SecuritizationDebtRecordIssueItemService } from '../../../../individual/securitization/services/securitization-debt-record-issue-item.service';
import { LoadingModule } from '../../../loading/loading.module';
import { LoadingService } from '../../../loading/services/loading.service';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';

@Component({
  selector: 'app-securitization-daily-issue-cbb-chart',
  templateUrl: './securitization-daily-issue-cbb-chart.component.html',
  styleUrls: ['./securitization-daily-issue-cbb-chart.component.scss'],
  providers: [LoadingModule.providers()],
})
export class SecuritizationDailyIssueCbbChartComponent implements OnInit, OnChanges {
  @Input() dateFrom: moment.Moment = moment();
  @Input() dateTo: moment.Moment = moment();

  result: any[] = [];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  animations: boolean = true;
  showDataLabel: boolean = true;
  legendTitle = '';
  currencyLabelFormatting = (value: string) => {
    return new CurrencyPipe(this.translate.currentLang).transform(value || '', 'BRL');
  };
  dateLabelFormatting = (value: string) => {
    return new LocalDatePipe(this.translate).transform(value || '');
  };
  colorScheme: Color = {
    domain: ['#4d93d8', '#66d84d'],
    name: '',
    selectable: false,
    group: ScaleType.Time,
  };

  constructor(
    private securitizationDebtRecordIssueItemService: SecuritizationDebtRecordIssueItemService,
    private loadingService: LoadingService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.dateFrom?.firstChange && !changes?.dateTo?.firstChange) {
      this.loadData();
    }
  }

  private loadData = () => {
    if (!this.dateFrom || !this.dateTo) return;

    this.loadingService.block();
    this.securitizationDebtRecordIssueItemService
      .getIssueCCBAmountByDate(this.dateFrom.format('yyyy-MM-DD'), this.dateTo.format('yyyy-MM-DD'))
      .pipe(finalize(() => this.loadingService.release()))
      .subscribe(response => {
        this.result = [...[]];
        response.forEach(item => {
          let monthy = this.result.find(m => m.name === item.issuedDate);
          const hasInserted = Boolean(monthy);

          monthy = monthy || {};
          monthy.name = item.issuedDate;
          monthy.series = monthy.series || [];
          monthy.series.push({
            name: this.translate.instant(`securitization.cbb.type.${item.ccbType}`),
            value: item.amount,
          });

          if (!hasInserted) {
            this.result.push(monthy);
          }
          this.result = [...this.result.sort((a, b) => a.name - b.name)];
        });
      });
  };
}
