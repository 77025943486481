export interface IProcess {
  id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  processTime: string;
  endedAt?: string;
  totalRecords: number;
  description: string;
  processResponse: string;
  progress: number;
  status: ProcessStatusEnum;
}

export enum ProcessStatusEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  INITIAL = 'INITIAL',
  CANCELED = 'CANCELED',
}

export interface IProcessItem {
  id: string;
  process: IProcess;
  status: ProcessItemStatusEnum;
  data: string;
  processResponse: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export enum ProcessItemStatusEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
