import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

import { StackBoolean } from '../../utils/functions/stack-boolean';
import { BlockerComponent } from '../blocker/blocker.component';

/**
 * Should add LoadingModule providers on component
 * to inject `ViewContainerRef` from component
 * providers: [LoadingModule.providers()],
 */
export class LoadingService {
  @StackBoolean()
  private loading: boolean;

  constructor(public componentFactoryResolver: ComponentFactoryResolver, public viewContainerRef: ViewContainerRef) {}

  block(viewContainerRef?: ViewContainerRef) {
    if (!this.loading) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BlockerComponent);
      viewContainerRef = viewContainerRef || this.viewContainerRef;
      viewContainerRef?.createComponent(componentFactory);
    }
    this.loading = true;
  }

  release(viewContainerRef?: ViewContainerRef) {
    this.loading = false;

    if (!this.loading) {
      viewContainerRef = viewContainerRef || this.viewContainerRef;
      if (viewContainerRef?.length > 0) {
        viewContainerRef.remove(0);
      }
    }
  }
}
