import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { interval, Observable, Subject } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil } from 'rxjs/operators';

import { IPage, Paging } from '../../../../model/page.model';
import { IProcess, IProcessItem, ProcessStatusEnum } from '../../../../model/process.model';
import { LoadingModule } from '../../loading/loading.module';
import { LoadingService } from '../../loading/services/loading.service';
import { ToastService } from '../../services/toast.service';
import { DialogProcessErrorComponent } from '../dialog-process-error/dialog-process-error.component';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss'],
  providers: [LoadingModule.providers()],
})
export class ProcessListComponent implements OnInit, OnDestroy {
  public displayedColumns = ['index', 'id'];
  public dataSource: MatTableDataSource<IProcess> = new MatTableDataSource();
  public ProcessStatusEnum = ProcessStatusEnum;

  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;
  public pageSizeOptions: number[] = [5, 10, 25, 100];

  private destroy$ = new Subject();

  @Input() processList: (paging?: Paging) => Observable<IPage<IProcess>>;
  @Input() processProgress: (processId: string) => Observable<IProcess>;
  @Input() processErrorList: (processId: string, paging?: Paging) => Observable<IPage<IProcessItem>>;
  @Output() cancel = new EventEmitter<IProcess>();

  constructor(
    private translate: TranslateService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getProcessList();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasDataSource() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length > 0;
  }

  onCancel(process: IProcess) {
    this.cancel.emit(process);
  }

  handlePageEvent(event: PageEvent) {
    this.getProcessList({ page: event.pageIndex, size: event.pageSize });
  }

  private getProcessList(paging: Paging = { page: 0, size: 10 }) {
    this.loadingService.block();
    this.processList(paging)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.toastService.openError(this.translate.instant('common.message.internalServerError'));
          throw err;
        }),
        finalize(() => this.loadingService.release()),
      )
      .subscribe(async res => {
        this.dataSource.data = res.content;
        this.length = res.totalElements;
        await Promise.all(
          this.dataSource.data.map(async process => {
            this.updateProcess(process);
          }),
        );
      });
  }

  private updateProcess = (process: IProcess) => {
    if (
      process.status === ProcessStatusEnum.SUCCESS ||
      process.status === ProcessStatusEnum.ERROR ||
      process.status === ProcessStatusEnum.CANCELED
    ) {
      process.processTime = moment.utc(moment(process.updatedAt).diff(moment(process.createdAt))).format('HH:mm:ss');
      process.progress = 100;
      return;
    }

    const updateProcessSubscription = interval(4000)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.processProgress(process.id)),
      )
      .subscribe(res => {
        if (
          res.status === ProcessStatusEnum.SUCCESS ||
          res.status === ProcessStatusEnum.ERROR ||
          res.status === ProcessStatusEnum.CANCELED ||
          !this.dataSource.data.find(p => p.id === process.id)
        ) {
          process.progress = 100;
          process.status = res.status;
          process.updatedAt = res.updatedAt;
          process.processTime = moment
            .utc(moment(process.updatedAt).diff(moment(process.createdAt)))
            .format('HH:mm:ss');
          updateProcessSubscription.unsubscribe();
        } else {
          process.progress = (res.progress / res.totalRecords) * 100;
        }
      });
  };

  showError(process: IProcess) {
    this.dialog.open(DialogProcessErrorComponent, { data: { process: process } });
  }
}
