import { ComponentFactoryResolver, NgModule, ViewContainerRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { BlockerComponent } from './blocker/blocker.component';
import { LoadingService } from './services/loading.service';

@NgModule({
  declarations: [BlockerComponent],
  imports: [MatProgressSpinnerModule],
  exports: [BlockerComponent],
  entryComponents: [BlockerComponent],
})
export class LoadingModule {
  static providers() {
    return [{ provide: LoadingService, useClass: LoadingService, deps: [ComponentFactoryResolver, ViewContainerRef] }];
  }
}
