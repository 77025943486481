import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../../auth/services/auth.service';

@Directive({
  selector: '[appAcl]',
})
export class AclDirective {
  constructor(
    private templateRef?: TemplateRef<any>,
    private viewContainer?: ViewContainerRef,
    private authService?: AuthService,
  ) {}

  @Input() set appAcl(roles: string[]) {
    if (!this.authService?.roles.some(role => roles.includes(role))) {
      if (this.templateRef !== null) {
        this.viewContainer?.createEmbeddedView(this.templateRef!!);
      }
    } else {
      this.viewContainer?.clear();
    }
  }
}
