import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { MatDateFormats } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { Subject } from 'rxjs';

// Register local data
registerLocaleData(localePt, 'pt-BR');

/**
 * @ngModule TranslateLoaderModule
 * @description
 *
 * Loads translation key files
 * AoT requires an exported function for factories
 *
 * @param http The HttpClient
 *
 * @returns void
 *
 */
export function translationHttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: 'assets/i18n/auth/', suffix: '.json' },
    { prefix: 'assets/i18n/cashadvance/', suffix: '.json' },
    { prefix: 'assets/i18n/common/', suffix: '.json' },
    { prefix: 'assets/i18n/loan/', suffix: '.json' },
    { prefix: 'assets/i18n/menu/', suffix: '.json' },
    { prefix: 'assets/i18n/securitization/', suffix: '.json' },
  ]);
}

/**
 * @ngModule TranslateLoaderModule
 * @description
 *
 * Sets translate settings
 *
 * @param translate The TranslateService
 * @param injector The angular Injector
 *
 * @returns void
 *
 */
export function translationAppInitializerFactory(translateService: TranslateService) {
  return () =>
    new Promise(async resolve => {
      translateService.use('pt-BR').subscribe({
        next: resolve,
        error: resolve,
      });
    });
}

export function getCurrentLang(translateService: TranslateService) {
  return translateService.currentLang;
}

export function dateFormat(): MatDateFormats {
  return {
    parse: {
      dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
      dateInput: 'input',
      monthYearLabel: { year: 'numeric', month: 'short' },
      dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
      monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
  };
}

export class CustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService) {}

  firstPageLabel = this.translate.instant('common.pagination.firstPage');
  itemsPerPageLabel = this.translate.instant('common.pagination.itemsPerPage');
  lastPageLabel = this.translate.instant('common.pagination.lastPage');

  nextPageLabel = this.translate.instant('common.pagination.nextPage');
  previousPageLabel = this.translate.instant('common.pagination.previousPage');

  getRangeLabel(page: number, pageSize: number, length: number): string {
    let amountPages = 1;
    let pageIndex = 1;
    if (length !== 0) {
      amountPages = Math.ceil(length / pageSize);
      pageIndex = page + 1;
    }
    return this.translate.instant('common.pagination.pageOf', { pageIndex: pageIndex, totalPages: amountPages });
  }
}
