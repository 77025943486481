export const environment = {
  production: false,
  cognitoUserPoolId: 'us-east-1_KF0cAqPFk',
  cognitoAppClientId: '311or25vmtjda148njgpih0i0b',
  loanApiurl: 'https://api-staging.rappibankbr.com',
  cashAdvanceApiurl: 'https://cash-advance-api-staging.rappibankbr.com',
  RDStationUrl: 'https://plugcrm.net/app#',
  notificationsApiUrl: 'https://notifications-api-staging.rappibankbr.com',
  securitizationApiUrl: 'https://securitization-api-staging.rappibankbr.com',
};
