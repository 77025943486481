export interface IUploadProgress {
  progress: number;
  status: UploadProgressStatusEnum;
}

export enum UploadProgressStatusEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}
