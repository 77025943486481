<div>
  <h1 mat-dialog-title>{{ 'common.processError' | translate }}</h1>
  <mat-dialog-content>
    <div class="dialog-card-list-item">
      <div class="row">
        <div class="col-md-6 label-item">
          <span>{{ 'common.process.name' | translate }}</span>
          <p>{{ 'common.processTitle.' + process?.name | translate }}</p>
        </div>
        <div class="col-md-6 label-item">
          <span>{{ 'common.status' | translate }}</span>
          <p class="label-error">{{ 'common.processStatus.' + process?.status | translate }}</p>
        </div>
        <div class="col-md-6 label-item">
          <span>{{ 'common.process.description' | translate }}</span>
          <p>{{ process?.description }}</p>
        </div>
        <div *ngIf="!isProcessResponseEmpty()" class="col-md-6 label-item">
          <span>{{ 'common.process.response' | translate }}</span>
          <p>{{ process.processResponse }}</p>
        </div>

        <mat-divider class="card-horizontal-divider"></mat-divider>
        <div class="container" *ngIf="!isProcessItemsEmpty()">
          <h2 mat-dialog-title>{{ 'common.processItem' | translate }}</h2>
          <table mat-table [dataSource]="processItems" class="mat-elevation-z8">
            <div class="row">
              <div class="col-sm-12">
                <ng-container matColumnDef="createdAt">
                  <th mat-header-cell *matHeaderCellDef>
                    <span>{{ 'common.createdAtField' | translate }}</span>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.createdAt | localDateHourSec }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>
                    <span>{{ 'common.status' | translate }}</span>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.status }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="processResponse">
                  <th mat-header-cell *matHeaderCellDef>
                    <span>{{ 'common.processItem.response' | translate }}</span>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.processResponse }}</span>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
              </div>
            </div>
          </table>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="handlePageEvent($event)"
    aria-label="Select page"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
