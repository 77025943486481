<div class="d-flex-align-center">
  <div class="container-vertical-align-margin-auto">
    <mat-card>
      <div [ngSwitch]="status | async">
        <div *ngSwitchCase="'STAND_BY'">
          <h1><app-logo></app-logo></h1>
          <div>
            <mat-form-field class="container-form-field">
              <mat-label>{{ 'auth.username' | translate }}</mat-label>
              <input
                matInput
                placeholder=" {{ 'common.placeholder.email' | translate }}"
                [(ngModel)]="loginData.username"
              />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="container-form-field">
              <mat-label>{{ 'auth.password' | translate }}</mat-label>
              <input matInput placeholder="********" [(ngModel)]="loginData.password" type="password" />
            </mat-form-field>
          </div>
          <div class="justify-content-around">
            <button mat-button (click)="requestVerificationCode()">
              {{ 'auth.button.forgotMyPassword' | translate }}
            </button>
            <button mat-button (click)="submit()" (keydown.enter)="submit()">{{ 'auth.signIn' | translate }}</button>
          </div>
        </div>
        <div *ngSwitchCase="'ONGOING'" class="vertical-align-center horizontal-align-center">
          <div class="padding-50px">
            <mat-spinner [diameter]="64"></mat-spinner>
          </div>
        </div>
        <div *ngSwitchCase="'NEW_PASSWORD_REQUIRED'">
          <div>
            <h1>{{ 'auth.header.createNewPassword' | translate }}</h1>
            <mat-form-field class="container-form-field">
              <mat-label>{{ 'common.name' | translate }}</mat-label>
              <input matInput [(ngModel)]="name" />
            </mat-form-field>
            <mat-form-field class="container-form-field">
              <mat-label>{{ 'auth.newPassword' | translate }}</mat-label>
              <input matInput [(ngModel)]="newPassword" />
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="completeNewPasswordChallenge()">Change my password</button>
          </div>
        </div>
        <div *ngSwitchCase="'PASSWORD_RESET'">
          <div>
            <h1>{{ 'auth.header.resetPassword' | translate }}</h1>
            <mat-form-field class="container-form-field">
              <mat-label>{{ 'auth.verificationCode' | translate }}</mat-label>
              <input matInput [(ngModel)]="verificationCode" />
            </mat-form-field>
            <mat-form-field class="container-form-field">
              <mat-label>{{ 'auth.newPassword' | translate }}</mat-label>
              <input matInput [(ngModel)]="newPassword" />
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="confirmPassword()">
              {{ 'auth.button.changePassword' | translate }}
            </button>
          </div>
        </div>
        <div *ngSwitchCase="'FORGOT_PASSWORD'">
          <div>
            <h1>{{ 'auth.header.confirmUsername' | translate }}</h1>
            <mat-form-field class="container-form-field">
              <mat-label>{{ 'auth.username' | translate }}</mat-label>
              <input
                matInput
                placeholder=" {{ 'common.placeholder.email' | translate }}"
                [(ngModel)]="loginData.username"
              />
            </mat-form-field>
          </div>
          <div class="justify-content-around">
            <button mat-flat-button color="primary" (click)="forgotPassword()">
              {{ 'auth.button.sendVerificationCode' | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
